import React from 'react';

import './Card.scss';

const Card = ({ count, text, icon }) => (
  <div className="card">
    <div className="left">
      <p className="count">{count}</p>
      <p className="text">{text}</p>
    </div>
    <span className="material-icons">{icon}</span>
  </div>
);

export default Card;
