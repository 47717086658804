import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import dateFormat from 'dateformat';

import './Layout.scss';

import { loggedIn } from '../../services/auth/auth';
import fetchApi from '../../services/fetch/fetch';

import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import Footer from '../footer/Footer';
import { NotificationManager, notif } from '../kit/notification/Notification';

const Layout = () => {
  const [valid, setIsValid] = useState(false);
  const [showSidebar, setShowSideBar] = useState(false);

  useEffect(() => {
    fetchApi('/apipharmacy/pharmacist/lastconnection').then((res) => {
      notif.addNotification(
        'Dernière connexion',
        `${res.name
        } le ${
          dateFormat(res.date, 'dd/mm/yy à HH:MM')}`
      );
    });
  }, []);

  useEffect(() => {
    if (!loggedIn()) {
      window.location.assign('/login');
    } else setIsValid(true);
  }, [loggedIn()]);

  return valid ? (
    <div className="layout">
      <NotificationManager />

      <Header menuClick={() => setShowSideBar(!showSidebar)} />
      <Sidebar onLinkClick={() => setShowSideBar(false)} show={showSidebar} />

      <div onClick={() => setShowSideBar(false)} className="content">
        <Outlet />
        <Footer />
      </div>
    </div>
  ) : null;
};

export default Layout;
