import decode from 'jwt-decode';

export const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

export const getToken = () => localStorage.getItem('feeli_pharmacy_token');

export const loggedIn = () => {
  const token = getToken();

  return !!token && !isTokenExpired(token);
};

export const setToken = (token) => localStorage.setItem('feeli_pharmacy_token', token);

export const logout = () => localStorage.removeItem('feeli_pharmacy_token');

export const getUser = () => decode(this.getToken());
