export default [
  {
    label: 'Acceuil',
    to: '/',
    icon: 'home'
  },
  {
    label: 'Assistance',
    to: false,
    icon: 'help'
  },
  {
    label: 'Profil',
    to: '/profil',
    icon: 'supervisor_account'
  },
  {
    label: 'Coupons',
    to: '/discounts',
    icon: 'money_off'
  },
  {
    label: 'Requetes',
    to: '/requests',
    icon: 'list'
  }
];
