import React from 'react';

import './Footer.scss';

const Footer = () => (
  <div className="footer">
    <p>2022 © Feeli</p>
  </div>
);

export default Footer;
