import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.scss';

import Layout from './component/layout/Layout';
import Discounts from './pages/discount/Discount';
import Home from './pages/home/Home';
import Profil from './pages/profil/Profil';
import Login from './pages/login/Login';
import AllRequests from './pages/requests/allRequests/AllRequests';
import PreInscri from './pages/preInscri/PreInscri';

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/pre-inscription" element={<PreInscri />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="profil" element={<Profil />} />
          <Route path="discounts" element={<Discounts />} />
          <Route path="requests" element={<AllRequests />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
