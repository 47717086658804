import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

import './Home.scss';

import fetchApi from '../../services/fetch/fetch';

import Card from '../../component/kit/card/Card';

const Home = () => {
  const [requests, setRequests] = useState([]);
  const [stats, setStat] = useState({
    usersCount: '',
    teleconsultCount: '',
    unusedDiscountCount: ''
  });

  useEffect(() => {
    fetchApi('/apipharmacy/pharmacist/request')
      .then((res) => setRequests(res.requests));

    fetchApi('/apipharmacy/pharmacist/statistics')
      .then((resC) => setStat({
        usersCount: resC.customers,
        teleconsultCount: resC.requests,
        unusedDiscountCount: resC.unusedDiscount
      }));
  }, []);

  const getCAOptions = () => {
    const labels = [];
    const data = [];
    const now = new Date();
    now.setMonth(new Date().getMonth() - 1);

    const options = {
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: labels
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: "Chiffre d'affaire",
          type: 'line',
          smooth: true,
          data,
          itemStyle: {
            color: '#D6AE71'
          },
          areaStyle: {
            color: '#D6AE7150'
          }
        }
      ]
    };

    if (requests.length) {
      const endDate = new Date(requests[0].createdAt);
      const startDate = new Date(
        requests[requests.length - 1].createdAt
      );

      while (startDate < endDate) {
        labels.push(
          `${startDate.getDate()}/${startDate.getMonth()}/${startDate.getFullYear()}`
        );

        const request = requests.filter(
          ({ createdAt, transactionId }) => {
            if (!transactionId) {
              return false;
            }

            const d = new Date(createdAt);

            if (
              d.getDate() === startDate.getDate()
              && d.getMonth() === startDate.getMonth()
              && d.getFullYear() === startDate.getFullYear()
            ) {
              return true;
            }

            return false;
          }
        );

        if (request.length === 0) {
          data.push(0);
        } else {
          data.push(request.length * 25);
        }

        startDate.setDate(startDate.getDate() + 1);
      }

      if (labels.length > 1) {
        options.dataZoom = [
          {
            startValue: `${now.getDate()}/${now.getMonth()}/${now.getFullYear()}`
          },
          {
            type: 'inside'
          }
        ];
      }
    }

    return options;
  };

  return (
    <div className="home">
      <div className="chart-block">
        <div className="title">
          <h4>Chiffre d&apos;affaires</h4>
          <div className="total">
            <p>{`Total ${new Date().getFullYear()} :`}</p>
            <span>
              {requests.filter(({
                createdAt,
                transactionId
              }) => transactionId === 'pharmacy' && new Date(createdAt).getFullYear() === new Date().getFullYear()).length * 25}
              &euro;
            </span>
          </div>
        </div>
        <div className="chart">
          <ReactEcharts
            style={{ height: '350px', width: '100%' }}
            option={getCAOptions()}
          />
        </div>
      </div>
      <div className="card-block">
        <Card text="Clients" count={stats.usersCount} icon="person_outline" />
        <Card text="COUPONS NON UTILISÉS" count={stats.teleconsultCount} icon="badge" />
        <Card text="TÉLÉCONSULTATIONS" count={stats.unusedDiscountCount} icon="file_copy" />
      </div>
    </div>
  );
};

export default Home;
