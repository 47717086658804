import React, { useEffect, useState } from 'react';

import './PreInscri.scss';

import fetchApi from '../../services/fetch/fetch';
import { logout } from '../../services/auth/auth';

import { NotificationManager, notif } from '../../component/kit/notification/Notification';

const PreInscri = () => {
  const [inputValue, setInputValue] = useState({
    name: '',
    resp_name: '',
    address: '',
    phone: '',
    email: '',
    question1: true,
    question2: '',
    question3: true,
    check1: false,
    check2: false
  });
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    logout();
  }, []);

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmit(true);

    fetchApi('/prepharmacy/preInscri/preInscription', {
      method: 'POST',
      body: JSON.stringify(inputValue)
    })
      .then(() => {
        notif.addNotification('Succes', 'Pré-inscription validé', 'success');
        setIsSubmit(false);
      })
      .catch((err) => {
        notif.addNotification('Erreur', err, 'error');
        setIsSubmit(false);
      });
  };

  const handleBool = (q, bool) => {
    setInputValue({ ...inputValue, [q]: bool });
  };

  return (
    <div className="preinscri">
      <NotificationManager />

      <img className="logo" src="/assets/images/logoFeeliProColor.jpg" alt="logo" />

      <div className="form-blocks">
        <form onSubmit={handleSubmit}>
          <div className="input-block">
            <div className="__flex">
              <div className="form-group">
                <input
                  required
                  name="name"
                  placeholder="Nom de la pharmacie"
                  className="form-input"
                  value={inputValue.name}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <input
                  required
                  name="resp_name"
                  placeholder="Nom et prénom du pharmacien"
                  className="form-input"
                  value={inputValue.resp_name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="__flex">
              <div className="form-group">
                <input
                  required
                  name="address"
                  placeholder="Adresse postale"
                  className="form-input"
                  value={inputValue.address}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <input
                  required
                  placeholder="Numéro de téléphone portable"
                  type="tel"
                  name="phone"
                  className="form-input"
                  value={inputValue.phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <input
                required
                type="email"
                placeholder="Adresse email"
                name="email"
                className="form-input"
                value={inputValue.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div style={{ padding: '15px 35px 20px' }}>
            <div className="form-group quest">
              <p>
                Vos patients sont-ils intéressés par
                la téléconsultation ?
              </p>
              <div className="btn-group">
                <button onClick={() => handleBool('question1', true)} className={inputValue.question1 ? 'active' : ''} type="button">Oui</button>
                <button onClick={() => handleBool('question1', false)} className={!inputValue.question1 ? 'active' : ''} type="button">Non</button>
              </div>
            </div>
            <div className="form-group quest">
              <p>
                Quelles sont les spécialités
                concernées ?
              </p>
              <div className="btn-group input">
                <input
                  required
                  type="text"
                  placeholder=""
                  name="question2"
                  className="form-input"
                  value={inputValue.question2}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group quest">
              <p>
                Proposez-vous déjà un
                dispositif de téléconsultation ?
              </p>
              <div className="btn-group">
                <button onClick={() => handleBool('question3', true)} className={inputValue.question3 ? 'active' : ''} type="button">Oui</button>
                <button onClick={() => handleBool('question3', false)} className={!inputValue.question3 ? 'active' : ''} type="button">Non</button>
              </div>
            </div>

            <div className="check-block">
              <input required type="checkbox" onChange={(e) => setInputValue({ ...inputValue, check1: e.target.checked })} />
              <p>Je confirme être pharmacien inscrit à l&apos;Ordre National des Pharmaciens en France</p>
            </div>
            <div className="check-block">
              <input required type="checkbox" onChange={(e) => setInputValue({ ...inputValue, check2: e.target.checked })} />
              <p>En soumettant ce formulaire j&apos;accepte que les données  saisies soient  exploitées dans le cadre de ma demande d&apos;informations sur le service FEELI </p>
            </div>

            <button
              style={{ opacity: isSubmit ? 0.6 : 1 }}
              disabled={isSubmit}
              type="submit"
              className="btn-submit"
            >
              Être rappelé
            </button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default PreInscri;
