import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Login.scss';

import fetchApi from '../../services/fetch/fetch';
import { logout, setToken } from '../../services/auth/auth';

import { NotificationManager, notif } from '../../component/kit/notification/Notification';

const Login = () => {
  const [inputValue, setInputValue] = useState({ username: '', password: '' });
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    logout();
  }, []);

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!inputValue.username || !inputValue.password) {
      notif.addNotification('Erreur', 'Veuillez remplir les champs !', 'error');
    } else {
      setIsSubmit(() => {
        fetchApi('/auth/pharmacist', {
          method: 'POST',
          body: JSON.stringify(inputValue)
        })
          .then((res) => {
            setToken(res.token);

            window.location.assign('/');
          })
          .catch((err) => {
            notif.addNotification('Erreur', err, 'error');
            setIsSubmit(false);
          });

        return true;
      });
    }
  };

  return (
    <div className="login">
      <NotificationManager />

      <img className="logo" src="/assets/images/logoFeeliProColor.jpg" alt="logo" />

      <div className="form-blocks">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <p>Identifiant</p>
            <input
              type="text"
              name="username"
              className="form-input"
              value={inputValue.username}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <p>Mot de passe</p>
            <input
              type="password"
              name="password"
              className="form-input"
              value={inputValue.password}
              onChange={handleChange}
            />
          </div>

          <button
            style={{ opacity: isSubmit ? 0.6 : 1 }}
            disabled={isSubmit}
            type="submit"
            className="btn-submit"
          >
            Connexion

          </button>
          <div style={{ marginTop: 20 }}>
            <Link style={{ color: 'grey' }} to="/pre-inscription">Pré-inscription</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
