import React from 'react';

import './Notification.scss';

export let notif = null;

export class NotificationManager extends React.Component {
  constructor() {
    super();

    this.state = {
      notifications: null
    };

    notif = this;

    this.counter = 0;
  }

  addNotification(title, message, type) {
    const remove = () => {
      this.setState({
        notifications: null
      });
    };

    const n = (
      <Notification
        title={title}
        message={message}
        remove={remove}
        type={type}
      />
    );

    this.setState({ notifications: n });
  }

  render() {
    return (
      <div className="notificationManager">
        {this.state.notifications}
      </div>
    );
  }
}

class Notification extends React.Component {
  constructor() {
    super();

    this.state = {
      closing: false
    };

    this.handleClose = this.handleClose.bind(this);

    this.to = null;
    this.toClose = null;
  }

  componentDidMount() {
    this.toClose = setTimeout(this.handleClose, 5000);
  }

  componentWillUnmount() {
    clearTimeout(this.to);
  }

  handleClose() {
    clearTimeout(this.toClose);
    this.setState({ closing: true });
    this.to = setTimeout(this.props.remove, 750);
  }

  render() {
    return (
      <div
        style={{ background: this.props.type === 'error' ? 'rgb(241, 180, 180)' : '#d6ae71' }}
        className={`notification ${
          this.state.closing ? 'bounceOut' : 'bounceIn'
        }`}
      >
        <span className="closeNotif" onClick={this.handleClose}>
          ×
        </span>
        <p className="title">{this.props.title}</p>
        <p className="msg">{this.props.message}</p>
      </div>
    );
  }
}
