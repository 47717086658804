import React from 'react';
import {
  Row, Col, Panel, Modal, Button
} from 'react-bootstrap';
import dateFormat from 'dateformat';

import './AllRequests.scss';

import { notif } from '../../../component/kit/notification/Notification';
import fetchApi from '../../../services/fetch/fetch';

class AllRequests extends React.Component {
  constructor() {
    super();

    this.state = {
      requests: [],
      totalPage: 0,
      filterRequests: null,
      isLoading: true,
      page: 1,
      showOrdoModal: false,
      selectedFile: []
    };
  }

  componentDidMount() {
    fetchApi('/apipharmacy/pharmacist/pharmacistRequest')
      .then((res) => this.setState({
        totalPage: res.countPages,
        requests: res.requests,
        isLoading: false
      }))
      .catch((err) => {
        notif.addNotification(
          'Erreur',
          `Erreur de recuperation des donnees request: ${err}`,
          'warning'
        );

        this.setState({ isLoading: false });
      });
  }

  onSort(sortKey, optionKey) {
    const data = this.state.requests;
    if (optionKey) {
      if (this.state.sort) {
        data.sort((a, b) => a[sortKey][optionKey].localeCompare(b[sortKey][optionKey]));
        this.setState({ sort: false });
      } else {
        data.sort((a, b) => b[sortKey][optionKey].localeCompare(a[sortKey][optionKey]));
        this.setState({ sort: true });
      }
    } else if (this.state.sort) {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      this.setState({ sort: false });
    } else {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
      this.setState({ sort: true });
    }

    this.setState({ requests: data });
  }

  updatePage(page) {
    this.setState({ page }, () => {
      fetchApi(`/apipharmacy/pharmacy/pharmacyRequest/?page=${page}`)
        .then((res) => {
          this.setState({
            totalPage: res.countPages,
            requests: res.requests
              .filter(({ userId }) => !!userId)
              .map((r) => ({
                ...r,
                doctorId: r.doctorId ? r.doctorId : { FName: '', LName: '' }
              }))
          });
        })
        .catch((err) => notif.addNotification(
          'Erreur',
          `Error while getting the users: ${err}`,
          'warning'
        ));
    });
  }

  ordoModal() {
    const data = this.state.selectedFile.filter((v) => v.name.split('-')[0] === 'ordonnance');

    return (
      <Modal show={this.state.showOrdoModal}>
        <Modal.Header>
          <Modal.Title>Ordonnances</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {data.map((v) => (
            <a key={v._id} className="view-ordo" href={v.path} target="_blank" rel="noopener noreferrer">{v.name}</a>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => this.setState({ showOrdoModal: false, selectedFile: [] })}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const requests = this.state.filterRequests || this.state.requests;

    return (
      <div className="allRequests">
        {this.ordoModal()}
        <Row>
          <Col md={12}>

            <Panel>
              <Panel.Heading>
                <h6>Requetes</h6>
              </Panel.Heading>
              <Panel.Body>
                <div>
                  <p>
                    Liste des demandes (Page
                    {' '}
                    <strong>{this.state.page}</strong>
                    {' '}
                    /
                    {' '}
                    {this.state.totalPage}
                    )
                  </p>

                  {!this.state.filterRequests && (
                    <div>
                      {this.state.page > 1 && (
                        <span
                          className="material-icons"
                          onClick={() => this.updatePage(this.state.page - 1)}
                        >
                          navigate_before
                        </span>
                      )}
                      {this.state.page < this.state.totalPage && (
                        <span
                          className="material-icons"
                          onClick={() => this.updatePage(this.state.page + 1)}
                        >
                          navigate_next
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="mT-40" />
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th
                          className="sortable"
                          onClick={() => this.onSort('userId', 'FName')}
                        >
                          Utilisateur
                        </th>
                        <th
                          className="sortable"
                          onClick={() => this.onSort('createdAt')}
                        >
                          Date de creation
                        </th>
                        <th
                          className="sortable"
                          onClick={() => this.onSort('type')}
                        >
                          Type
                        </th>
                        <th
                          className="sortable"
                          onClick={() => this.onSort('state')}
                        >
                          Etat
                        </th>
                        <th
                          className="sortable"
                          onClick={() => this.onSort('doctorId', 'FName')}
                        >
                          Docteur
                        </th>
                        <th>{}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.isLoading ? (
                        <tr><td>Récupération des données en cours...</td></tr>
                      ) : requests.map((r) => (
                        <tr key={r._id}>
                          <td>
                            {r.userId.FName}
                            {' '}
                            {r.userId.LName}
                          </td>
                          <td>{dateFormat(r.createdAt, 'dd/mm/yy HH:MM')}</td>
                          <td>{r.type}</td>
                          <td>{r.state}</td>
                          <td>
                            {r.doctorId
                              ? `${r.doctorId.FName} ${r.doctorId.LName}`
                              : ''}
                          </td>
                          {r.attachments.length > 0 && (
                            <td>
                              <p onClick={() => this.setState({ showOrdoModal: true, selectedFile: r.attachments })} className="view-ordo">Voir les ordonnances</p>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AllRequests;
