import React from 'react';

import './Profil.scss';

const Profil = () => (
  <div className="profil">
    <p>Profil</p>
  </div>
);

export default Profil;
