import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';

import './Discount.scss';

import fetchApi from '../../services/fetch/fetch';

import { notif } from '../../component/kit/notification/Notification';

const Discounts = () => {
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchApi('/apipharmacy/discount')
      .then((res) => {
        setDiscounts(res.discounts);
        setLoading(false);
      })
      .catch((err) => {
        notif.addNotification(
          'Erreur',
          err,
          'err'
        );
        setLoading(false);
      });
  }, []);

  const handleRemove = (id) => {
    const discount = discounts.find(({ _id }) => _id === id);

    Swal.fire({
      title: 'Etes-vous sur ?',
      text: `Souhaitez-vous réellement supprimer le coupon ${discount.name} ? Cette operation est irrevérsible.`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        fetchApi(`/apipharmacy/discount/${discount._id}`, {
          method: 'DELETE'
        }).then(() => {
          setDiscounts(discounts.filter(({ _id }) => _id !== id));

          Swal.fire(
            'Supprimer',
            'Coupon supprimé',
            'success'
          );
        });
      }
    });
  };

  const onSort = (sortKey, sortSecond) => {
    if (sortSecond) {
      if (sort) {
        discounts.sort((a, b) => a[sortKey][sortSecond]
          .toString().localeCompare(b[sortKey][sortSecond]));
      } else {
        discounts.sort((a, b) => b[sortKey][sortSecond]
          .toString().localeCompare(a[sortKey][sortSecond]));
      }
    } else {
      const data = discounts;
      if (sort) {
        data.sort((a, b) => a[sortKey].toString().localeCompare(b[sortKey]));
        setSort(false);
      } else {
        data.sort((a, b) => b[sortKey].toString().localeCompare(a[sortKey]));
        setSort(true);
      }
      setDiscounts(data);
    }
  };

  const addDiscount = () => {
    Swal.fire({
      title: 'Ajout d\'un coupon téléconsultation',
      text: 'Nom du coupon',
      input: 'text',
      inputValue: `FE${Math.random().toString(36).substr(2, 3).toUpperCase()}${new Date().getTime()}`,
      inputPlaceholder: 'Nom du coupon',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Ajouter',
      cancelButtonText: 'Annuler',
      reverseButtons: true
    }).then((discountName) => {
      if (!discountName.isConfirmed) {
        return null;
      }

      fetchApi('/apipharmacy/discount', {
        method: 'POST',
        body: JSON.stringify({
          name: discountName.value
        })
      }).then((res) => {
        if (res.success) {
          Swal.fire(
            'Ajouter',
            'Coupon ajouté',
            'success'
          );
          setDiscounts([...discounts, {
            _id: res.id,
            name: discountName.value,
            updatedAt: new Date(),
            used: false
          }]);
        } else {
          notif.addNotification('Erreur', res.message, 'error');
        }
      });

      return null;
    });
  };

  console.log(loading);

  return (
    <div className="discounts">
      <h4>Coupons de Réduction</h4>
      <div className="add-discount">
        <button
          type="button"
          onClick={() => addDiscount()}
        >
          Ajouter une téléconsultation

        </button>
      </div>
      <div className="table-block">
        <table className="table">
          <thead>
            <tr>
              <th
                className="title"
                onClick={() => onSort('name')}
              >
                Nom du coupon
              </th>
              <th
                className="title"
                onClick={() => onSort('createdAt')}
              >
                Date d&apos;émission
              </th>
              <th className="title">
                Expiré
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? <p style={{ textAlign: 'center' }}>Loading ...</p> : discounts.map((d) => (
              <tr key={d._id}>
                <td>
                  {d.name}
                </td>
                <td>
                  {dateFormat(
                    d.createdAt,
                    'dd/mm/yy HH:MM'
                  )}
                </td>
                <td>
                  {d.expired
                    ? (
                      <span
                        className="material-icons danger"
                      >
                        dangerous
                      </span>
                    )
                    : (
                      <span className="material-icons success">
                        check_circle_outline
                      </span>
                    )}
                </td>
                <td>
                  <span
                    className="material-icons"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemove(
                      d._id
                    )}
                  >
                    highlight_off
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Discounts;
