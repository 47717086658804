import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import routes from '../../services/routes/routes';

import './Sidebar.scss';

const Linked = ({
  to, icon, label, onClick
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link onClick={onClick} className={`link ${match ? 'active' : ''}`} to={to}>
      <span className="material-icons">{icon}</span>
      <span>{label}</span>
    </Link>
  );
};

const Sidebar = ({ show, onLinkClick }) => (
  <div className={`sidebar ${show ? 'show' : ''}`}>
    <p className="title">Home</p>

    <div className="list">
      {routes.map((v) => (
        !v.to ? (
          <a href="mailto:infos@feeli.io" key={v.to} className="link">
            <span className="material-icons">{v.icon}</span>
            <span>
              {v.label}
            </span>
          </a>
        )
          : (
            <Linked onClick={onLinkClick} key={v.to} to={v.to} label={v.label} icon={v.icon} />
          )
      ))}
      <span onClick={() => window.location.assign('/login')} className="link logout">
        Se deconnecter
      </span>
    </div>
  </div>
);

export default Sidebar;
