import config from '../../config/config';
import { getToken } from '../auth/auth';

const fetchApi = (url, options) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`
  };

  return fetch(`${config.apiUrl}${url}`, { ...options, headers })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === false) {
        return Promise.reject(res.message);
      }

      return res;
    })
    .catch((err) => Promise.reject(err));
};

export default fetchApi;
