import React from 'react';

import './Header.scss';

const Header = ({ menuClick }) => (
  <div className="header">
    <span className="left">
      <span onClick={menuClick} className="material-icons menu">menu</span>
      <img src="/assets/images/logo_feeli_color.png" alt="Logo" />
      <span>Espace Pharmacie</span>
    </span>

    <span className="right">
      <img
        src="/assets/images/pharmacy-pp.png"
        alt="ph-logo"
        className="ph-logo"
      />
    </span>
  </div>
);

export default Header;
